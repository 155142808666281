<template>
  <b-modal id="pieceModal" ref="pieceModal" @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("ROOM") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRE">
      <div class="center">
        <b-form-group :label="$t('WORDING') + '*'" label-for="lib">
          <b-form-input
            id="lib"
            v-model="v$.newpiece.lib.$model"
            :state="validateState('lib')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="lib-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newpiece.lib.$errors"
            id="lib-feedback"
          ></error-handle>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('CATEGORIES') + '*'" label-for="cat">
          <b-form-select
            v-model="newpiece.categorie"
            :options="options"
            id="cat"
            size="sm"
          ></b-form-select>
          <div v-if="erreurlist.categorie" class="error-message">
            <ul v-if="Array.isArray(erreurlist.categorie)">
              <span v-for="(e, index) in erreurlist.categorie" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.categorie }}</span>
          </div>
          <div v-if="v$.newpiece.categorie.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('GALLERY')">
          <multiselect
            v-model="newpiece.galerie"
            :placeholder="$t('CHOOSE_IMAGE')"
            label="titre"
            track-by="titre"
            :options="getAllgallery"
            :option-height="72"
            :custom-label="customLabel"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="option">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.titre }}
                  </span>
                </span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option">
                <img
                  class="option__image mr-4"
                  :src="`${galleryUrl}${props.option.image}`"
                  alt="None"
                />
            
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.titre }}
                  </span>
                </span>
              </div>
            </template>
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.gallery_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.gallery_id)">
              <span v-for="(e, index) in erreurlist.gallery_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.gallery_id }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="newpiece.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="computedAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.entreprise_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.entreprise_id)">
              <span v-for="(e, index) in erreurlist.entreprise_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.entreprise_id }}</span>
          </div>
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRE">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingpiece" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      erreurlist: {
        lib: null,
        gallery_id: null,
        categorie: null,
        entreprise_id: null,
      },
      newpiece: {
        lib: null,
        lang: "fr",
        categorie: null,
        favoris: null,
        galerie: null,
      },
      options: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      galleryUrl: domains.gallery,
      page: 1,
      per_page: 1000,
      errorS: false,
      box: "",
    };
  },
  validations() {
    return {
      newpiece: {
        lib: { required, maxLength: maxLength(100) },
        categorie: { required },
      },
    };
  },
  methods: {
    ...mapActions(["store_piece", "allgallery", "all_entreprises"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newpiece[name];
      return $dirty ? !$error : null;
    },

    customLabel({ title }) {
      return `${title}`;
    },
    async addPRE() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        lib: null,
        gallery_id: null,
        categorie: null,
        entreprise_id: null,
      };
      const data = {
        lib: this.newpiece.lib,
        categorie: this.newpiece.categorie,
        gallery_id: this.newpiece.galerie ? this.newpiece.galerie.id : null,
        favoris: this.newpiece.favoris === null ? "false" : "true",
        lang: "fr",
      };
      if (this.newpiece.entreprise && this.newpiece.entreprise.id) {
        data.entreprise_id = this.newpiece.entreprise.id;
      }
      await this.store_piece(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["pieceModal"].hide();
      this.newpiece = {
        lib: null,
        lang: "fr",
        categorie: null,
        favoris: null,
        galerie: null,
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        lib: null,
        gallery_id: null,
        categorie: null,
        entreprise_id: null,
      };
    },

    close(bv) {
      if (this.$refs["pieceModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  mounted() {
    if (!this.getAllgallery) {
      this.allgallery({ page: this.page, per_page: this.perPage });
      if (this.isSuperAdmin)
        if (!this.getAllentreprises) {
          this.all_entreprises({
            page: this.page,
            per_page: this.perPage,
            search: this.search,
          });
        }
    }
  },

  computed: {
    ...mapGetters([
      "getAllgallery",
      "getAllentreprises",
      "getOnlineUser",
      "getLoadingpiece",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
